const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
const PKCE_CHARSET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
const RECOMMENDED_CODE_VERIFIER_LENGTH = 96;

const encode = (arraybuffer: ArrayBuffer): string => {
  const bytes = new Uint8Array(arraybuffer),
    len = bytes.length;
  let base64 = '';

  for (let i = 0; i < len; i += 3) {
    base64 += CHARS[bytes[i] >> 2];
    base64 += CHARS[((bytes[i] & 3) << 4) | (bytes[i + 1] >> 4)];
    base64 += CHARS[((bytes[i + 1] & 15) << 2) | (bytes[i + 2] >> 6)];
    base64 += CHARS[bytes[i + 2] & 63];
  }

  if (len % 3 === 2) {
    base64 = base64.substring(0, base64.length - 1) + '=';
  } else if (len % 3 === 1) {
    base64 = base64.substring(0, base64.length - 2) + '==';
  }

  return base64;
};

const getCodeVerifier = (): string => {
  const output = new Uint32Array(RECOMMENDED_CODE_VERIFIER_LENGTH);
  crypto.getRandomValues(output);
  const codeVerifier = Array.from(output)
    .map((num: number) => PKCE_CHARSET[num % PKCE_CHARSET.length])
    .join('');
    return codeVerifier;
};

export type PKCECodePair = {
  codeVerifier: string;
  codeChallenge: Promise<string>;
  createdAt: Date;
};

export const base64URLEncode = (str: Buffer): string => {
  return str.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
};

export const createPKCECodes = (): PKCECodePair => {
  const codeVerifier = getCodeVerifier();
  const codeChallenge = generateCodeChallenge(codeVerifier);
  const createdAt = new Date();
  const codePair = {
    codeVerifier,
    codeChallenge,
    createdAt,
  };
  return codePair;
};

async function generateCodeChallenge(codeVerifier: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await window.crypto.subtle.digest('SHA-256', data);
  const base64Digest = encode(digest);
  // you can extract this replacing code to a function
  return base64Digest.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}
