import {
  Badge,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useEffect, useState } from 'react';
import api from 'services/api';
import SearchTableOverview from 'views/leads/lista/components/SearchTableOverview';
import { Files, fetchDataFiles } from './hooks/fetchDataFiles';
import { createColumnHelper } from '@tanstack/react-table';
import { MdEdit, MdInfo, MdSupervisedUserCircle } from 'react-icons/md';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

export default function ListaFiles() {
  const textColor = useColorModeValue('navy.700', 'white');
  const navigate = useNavigate();

  const { user } = useAuth();
  useEffect(() => {
    if(!user.isCordenador){
      navigate('/admin/leads/lista');
    }
  }, []);

  const handleArquivoSelecionado = (arquivoId: number) => {
    navigate(`/admin/arquivos/leads/${arquivoId}`);
  };

  const columnHelper = createColumnHelper<Files>();
  const columns = [
     columnHelper.accessor('nomeArquivo', {
       id: 'nomeArquivo',
       header: () => (
         <Text
           justifyContent="space-between"
           align="center"
           fontSize={{ sm: '10px', lg: '12px' }}
           color="gray.400"
         >
           Nome
         </Text>
       ),
       cell: (info: any) => (
         <Text color={textColor} fontSize="sm" fontWeight="700">
           {info.getValue() ?? ""}
         </Text>
       ),
     }),
    columnHelper.accessor('dataCadastro', {
      id: 'dataCadastro',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Cadastro
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()
            ? format(new Date(info.getValue()), 'dd/MM/yyyy HH:mm:ss')
            : info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('dataProcessamento', {
      id: 'dataProcessamento',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Processamento
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="600">
          {info.getValue()
            ? format(new Date(info.getValue()), 'dd/MM/yyyy HH:mm:ss')
            : info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          STATUS
        </Text>
      ),
      cell: (info) => (
        <Badge
          colorScheme={
            info.getValue() === 'AGENDADO'
              ? 'red'
              : info.getValue() === 'PROCESSANDO'
              ? 'orange'
              : 'green'
          }
          color={
            info.getValue() === 'AGENDADO'
              ? 'red.500'
              : info.getValue() === 'PROCESSANDO'
              ? 'orange.500'
              : 'green.500'
          }
          fontSize="sm"
          fontWeight="600"
        >
          {info.getValue() ? info.getValue().toLowerCase() : ''}
        </Badge>
      ),
    }),
    columnHelper.accessor('id', {
      id: 'id',
      header: () => <Text></Text>,
      cell: (info) => (
        <Flex cursor="pointer" h="max-content" w="max-content">
          <Button
            leftIcon={<MdInfo />}
            colorScheme="teal"
            variant="solid"
            onClick={() => {
              handleArquivoSelecionado(info.getValue());
            }}
          >
            Leads
          </Button>
        </Flex>
      ),
    }),
  ];

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
      <Card px="0px">
        <SearchTableOverview
          fetchData={fetchDataFiles}
          columns={columns}
          showDatePicker
          showFilter={false}
        />
      </Card>
    </Flex>
  );
}
