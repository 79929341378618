import { Container, Spinner } from "@chakra-ui/react"
import { useAuth } from "hooks/useAuth";
import { Navigate, Route, Routes } from "react-router-dom";
import AdminLayout from './layouts/admin';
import LeadsLayout from './layouts/leads';
import { useState } from "react";
import initialTheme from './theme/theme';

const LayoutRoutes = () => {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);

  const { isAuthenticated } = useAuth()

  if (!isAuthenticated) {
    return (
      <Container w='100%' h='100%' centerContent mt='25%'>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Container>
    )
  }

  return (
    <>
      <Routes>
        <Route
          path="admin/*"
          element={
            <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
          }
        />
        <Route
          path="leads/*"
          element={
            <LeadsLayout theme={currentTheme} setTheme={setCurrentTheme} />
          }
        />
        <Route
          path="/"
          element={<Navigate to="/admin/leads/lista" replace />}
        />
      </Routes>
    </>
  )
}

export default LayoutRoutes;