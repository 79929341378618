import { validaAcesso } from 'types/validaAcesso'
import { JWTIDToken } from '../auth/AuthService'

type CountryType = 'BR'

interface IUserDocument {
  country: CountryType
  identification: string
  type: 'CPF'
}

interface ILegacyIds {
  country: CountryType
  personId: number | null
  employeeId: number | null
  affiliateId: number | null
  salesPromoterId: number | null
}

interface promotor {
  idFranquia?: number;
  idOwnerHubspot?: number;
  idPessoa?: number;
  idPromotorVendas?: number;
  idUsuarioHubspot?: number;
  nomeFantasiaFranquia?: string;
  listaDeRolesPromotor?: number[];
  perfilPromotor?: string;
}


export interface IUser {
  nome: string;
  mensagem: string;
  promotor: promotor;
  exigirAcessoCordenador?: boolean;
  rolesExigidasParaAcessoCordenador: number[];
  isCordenador: boolean;
  email: string
  pictureUrl: string
  legacyIds: ILegacyIds
  document: IUserDocument
}

export class User implements IUser {
  nome: string

  mensagem: string;

  promotor: promotor;

  exigirAcessoCordenador?: boolean;

  rolesExigidasParaAcessoCordenador: number[];

  isCordenador: boolean;

  email: string

  pictureUrl: string

  legacyIds: ILegacyIds

  document: IUserDocument

  isNull = false

  constructor(
    name: string,
    email: string,
    promotor: promotor,
    exigirAcessoCordenador: boolean = true,
    rolesExigidasParaAcessoCordenador: number[],
    mensagem: string,
    isCordenador:boolean
  ) {
    this.nome = name
    this.email = email
    this.promotor = promotor
    this.exigirAcessoCordenador = exigirAcessoCordenador !== null ?? true
    this.rolesExigidasParaAcessoCordenador = rolesExigidasParaAcessoCordenador
    this.mensagem = mensagem
    this.isCordenador = isCordenador
  }

  static createFromIDToken(idToken: JWTIDToken, validaAcesso: validaAcesso, isCordenador: boolean) {
    const country = 'BR'
    return new User(
      idToken.name,
      idToken.email,
      validaAcesso.promotor,
      validaAcesso.exigirAcessoCordenador !== null ?? true,
      validaAcesso.rolesExigidasParaAcessoCordenador,
      validaAcesso.mensagem,
      isCordenador
    )
  }

  static createNullUser() {
    const country = 'BR'
    const user = new User(
      '',
      '',
      null,
      true,
      [],
      '',
      false
    )

    user.isNull = true
    return user
  }
}
