import { Icon } from '@chakra-ui/react';
import {
  MdAppRegistration,
  MdHome,
} from 'react-icons/md';

import Register from 'views/leads/register';
import ListaLeads from 'views/leads/lista';
import Importar from 'views/arquivos/importar';
import ListaFiles from 'views/arquivos/lista';
import LeadsFiles from 'views/arquivos/leads';
import RegisterCampanha from 'views/campanha/register';
import ListaCampanha from 'views/campanha/lista';

const routes = [
  {
    name: 'Dashboard',
    path: '/leads/lista',
    layout: '/admin',
    component: <ListaLeads />,
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    collapse: false
  },
  {
    name: 'Cadastrar',
    path: '/leads/register',
    layout: '/admin',
    component: <Register />,
    icon: <Icon as={MdAppRegistration} width="20px" height="20px" color="inherit" />,
    collapse: false,
  },
  {
    name: 'Importar',
    path: '/arquivos/importar',
    layout: '/admin',
    component: <Importar />,
    icon: <Icon as={MdAppRegistration} width="20px" height="20px" color="inherit" />,
    collapse: false,
    isGerencial: true,
  },
  {
    name: 'Importações',
    path: '/arquivos/lista',
    layout: '/admin',
    component: <ListaFiles />,
    icon: <Icon as={MdAppRegistration} width="20px" height="20px" color="inherit" />,
    collapse: false,
    isGerencial: true,
  },
  {
    name: 'Importações',
    path: '/arquivos/leads/:arquivoId',
    layout: '/admin',
    component: <LeadsFiles />,
    icon: <Icon as={MdAppRegistration} width="20px" height="20px" color="inherit" />,
    collapse: false,
    secondary: true,
    isGerencial: true,
  },
  {
    name: 'Cadastrar Campanha',
    path: '/campanha/register',
    layout: '/admin',
    component: <RegisterCampanha />,
    icon: <Icon as={MdAppRegistration} width="20px" height="20px" color="inherit" />,
    collapse: false,
    isGerencial: true,
  },
  {
    name: 'Cadastrar Campanha',
    path: '/campanha/lista',
    layout: '/admin',
    component: <ListaCampanha />,
    icon: <Icon as={MdAppRegistration} width="20px" height="20px" color="inherit" />,
    collapse: false,
    isGerencial: true,
  }
];

export default routes;