import {
  Badge,
  Box,
  useToast,
  Icon,
  SimpleGrid,
  useColorModeValue,
  Text,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
// Custom components
import Card from 'components/card/Card';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';

// Assets
import {
  MdAttachMoney,
  MdCreditCard,
  MdDownloading,
  MdOutlineBarChart,
  MdPerson,
  MdRedo,
  MdShowChart,
  MdVerified,
  MdWarning,
} from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

import SearchTableOverview from 'views/leads/lista/components/SearchTableOverview';
import { fetchDataLeads, leads } from 'views/leads/lista/hooks/fetchDataLeads';
import { AxiosResponse } from 'axios';
import api from 'services/api';
import { useAuth } from 'hooks/useAuth';

type Totais = {
  angendado: number;
  recusado: number;
  aprovado: number;
  leads: number;
};

type ResponseTotais = {
  status: string;
  total: number;
};


export default function LeadsFiles() {
    const { arquivoId } = useParams();

    const { user } = useAuth();
    const navigate = useNavigate();
    useEffect(() => {
      if(!user.isCordenador){
        navigate('/admin/leads/lista');
      }
    }, []);
    
  // Chakra Color Mode
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const toast = useToast();
  const textColor = useColorModeValue('navy.700', 'white');
  const columnHelper = createColumnHelper<leads>();
  const columns = [
    columnHelper.accessor('nome', {
      id: 'nome',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Nome
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('cpfCnpj', {
      id: 'cpfCnpj',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          CPF
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="600">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('email', {
      id: 'email',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          EMAIL
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="600">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('telefone', {
      id: 'telefone',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          TELEFONE
        </Text>
      ),
      cell: (info) => (
        <Text color={'green.500'} fontSize="sm" fontWeight="600">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('statusLead', {
      id: 'statusLead',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          STATUS
        </Text>
      ),
      cell: (info) => (
        <Badge
          colorScheme={
            info.getValue() === 'RECUSADO'
              ? 'red'
              : info.getValue() === 'AGENDADO'
              ? 'orange'
              : 'green'
          }
          color={
            info.getValue() === 'RECUSADO'
              ? 'red.500'
              : info.getValue() === 'AGENDADO'
              ? 'orange.500'
              : 'green.500'
          }
          fontSize="sm"
          fontWeight="600"
        >
          {info.getValue().toLowerCase()}
        </Badge>
      ),
    })
  ];

  const [totaisLeads, setTotaisLeads] = useState<Totais>({
    angendado: 0,
    aprovado: 0,
    leads: 0,
    recusado: 0,
  });

  const getToatisLeads = async () => {
    try {
      const response: AxiosResponse<ResponseTotais[]> = await api.get(
        `Leads/Totais?IdImportacao=${arquivoId}`,
      );

      const filteredData = response.data.reduce(
        (accumulator: Record<string, number>, x) => {
          accumulator[x.status] = (accumulator[x.status] || 0) + x.total;
          return accumulator;
        },
        {},
      );

      const totais: Totais = {
        angendado: filteredData['AGENDADO'] || 0,
        aprovado: filteredData['APROVADO'] || 0,
        recusado: filteredData['RECUSADO'] || 0,
        leads: filteredData['Total'] || 0,
      };

      setTotaisLeads(totais);
    } catch (error: any) {
      toast({
        title: `Erro:`,
        description: error,
        status: 'error',
        position: 'top-right',
        isClosable: false,
      });
    }
  };

  useEffect(() => {
    getToatisLeads();
  }, []);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 4 }} gap="20px" mb="20px">
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdVerified} color={brandColor} />
              }
            />
          }
          name="Leads Aprovados"
          value={totaisLeads.aprovado.toString()}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdWarning} color={brandColor} />
              }
            />
          }
          name="Leads Recusados"
          value={totaisLeads.recusado.toString()}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdDownloading} color={brandColor} />}
            />
          }
          name='Leads Agendados'
          value={totaisLeads.angendado.toString()}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdOutlineBarChart} color={brandColor} />
              }
            />
          }
          name='Total de Leads'
          value={totaisLeads.leads.toString()}
        />
      </SimpleGrid>
      <Card>
        <SearchTableOverview fetchData={fetchDataLeads} columns={columns} idImportacao={arquivoId} />
      </Card>
    </Box>
  );
}
