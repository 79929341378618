import './assets/css/App.css';
import { ChakraProvider } from '@chakra-ui/react';
import initialTheme from './theme/theme';
import { useState } from 'react';
import { UseAuthProvider } from 'hooks/useAuth';
import Layout from 'Layout';
import { QueryClient, QueryClientProvider } from 'react-query';

export default function Main() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  const queryClient = new QueryClient();
  return (
    <UseAuthProvider>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={currentTheme}>
          <Layout />
        </ChakraProvider>
      </QueryClientProvider>
    </UseAuthProvider>
  );
}
