// Chakra imports
import { Box, Flex } from '@chakra-ui/react';
import Information from 'views/leads/register/components/Information';

export default function Register() {
  // Chakra Color Mode
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex direction="column">
        <Information />
      </Flex>
    </Box>
  );
}
