import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  SimpleGrid,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import Loading from 'components/loading';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import api from 'services/api';
import { ApiResponse } from 'types/api-response';
import { apiReturn } from 'types/data-response';
import { Leads } from 'types/leads';
import { formatarData, maskCpfCnpj, maskTelefone } from 'util/maskInputs';
import { validarCPFCNPJ } from 'util/validaCPFCNPJ';
import { validarEmail, validarTelefone } from 'util/validaInputsRegister';
import { Campanha } from 'views/campanha/lista/hooks/fetchDataCampanha';
import * as Yup from 'yup';

export default function Information(props: { [x: string]: any }) {
  const { ...rest } = props;
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      nome: '',
      cpfCnpj: '',
      email: '',
      telefone: '',
      endereco: '',
      cidade: '',
      estado: '',
      dataHorarioLigacaoSugerida: '',
      idCampanhaLead: null,
    },
    validationSchema: Yup.object({
      nome: Yup.string().required('Obrigatório'),
      cpfCnpj: Yup.string()
        .notRequired()
        .test('isValid', 'CPF/CNPJ inválido', (value) => {
          if (value === null || value === undefined) return true;

          if (validarCPFCNPJ(value || '')) return true;
        }),
      email: Yup.string()
        .email('Email inválido')
        .notRequired()
        .test('isValid', 'Email inválido', (value) => {
          if (value === null || value === undefined) return true;

          if (validarEmail(value || '')) return true;
        }),
      telefone: Yup.string()
        .required('Obrigatório')
        .test('isValid', 'Número inválido', (value) =>
          validarTelefone(value || ''),
        ),
      endereco: Yup.string().notRequired(),
      dataHorarioLigacaoSugerida: Yup.string().notRequired(),
      idCampanhaLead: Yup.string().notRequired(),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);

      const {
        nome,
        cpfCnpj,
        email,
        telefone,
        endereco,
        dataHorarioLigacaoSugerida,
        idCampanhaLead,
      } = values;
      const jsonEnvio: Leads = {
        nome,
        cpfCnpj: cpfCnpj ? cpfCnpj.replace(/\D/g, '') : null,
        email: email || null,
        telefone: telefone.replace(/\D/g, ''),
        endereco: endereco || null,
        dataHorarioLigacaoSugerida: dataHorarioLigacaoSugerida
          ? formatarData(dataHorarioLigacaoSugerida)
          : null,
        idCampanhaLead: idCampanhaLead !== '' ? idCampanhaLead : null,
      };
      try {
        const response: AxiosResponse<ApiResponse> = await api.post(
          'leads',
          jsonEnvio,
        );
        if (response.data.isSuccess) {
          toast({
            title: `Lead cadastrado com sucesso`,
            status: 'success',
            position: 'top-right',
            isClosable: true,
          });
          resetForm();

          setIsLoading(false);
        }
      } catch (error: any) {
        setIsLoading(false);
        toast({
          title: `Ocorreu um erro`,
          description: error.join('\n'),
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
    },
  });

  async function fatchCamapanhasOption() {
    try {
      const response: apiReturn = await api.get('CampanhaLeads/Vigentes');

      return response.data.items as Campanha[];
    } catch (error: any) {
      toast({
        title: `Ocorreu um erro`,
        description: error,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  async function populateCampanhaOptions() {

    const selectElement = document.getElementById('idCampanhaLead');
    const options = await fatchCamapanhasOption();

    selectElement.innerHTML = '';

    const optionElement = document.createElement('option');
    optionElement.value = '';
    optionElement.textContent = `Selecionar...`;
    selectElement.appendChild(optionElement);
    if (options?.length > 0) {
      options.forEach((option) => {
        const optionElement = document.createElement('option');
        optionElement.value = option.id.toString();
        optionElement.textContent = `${option.id} - ${option.nome}`;
        selectElement.appendChild(optionElement);
      });
    }
  }

  useEffect(() => {
    populateCampanhaOptions();
  }, []);

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <FormControl isInvalid={formik.errors.nome && formik.touched.nome}>
        <Card mb="20px" {...rest}>
          <Flex direction="column" mb="30px" ms="10px">
            <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
              Cadastro de lead
            </Text>
          </Flex>

          <SimpleGrid
            columns={{ sm: 1, md: 2 }}
            spacing={{ base: '20px', xl: '20px' }}
          >
            <InputField
              mb="10px"
              me="30px"
              id="nome"
              label="Nome"
              value={formik.values.nome}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.nome}
            />

            <InputField
              mb="10px"
              me="30px"
              id="dataHorarioLigacaoSugerida"
              label="Melhor horário para contato"
              type="datetime-local"
              max="9999-12-31T23:59"
              value={formik.values.dataHorarioLigacaoSugerida}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.dataHorarioLigacaoSugerida}
            />
            <Box>
              <InputField
                mb="0px"
                id="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.email}
              />
            </Box>
            <Box>
              <InputField
                mb="0px"
                me="30px"
                id="cpfCnpj"
                label="CPF/CNPJ"
                value={formik.values.cpfCnpj}
                maxLength="18"
                onChange={(event: any) => {
                  const maskedValue = maskCpfCnpj(event.target.value);
                  formik.setFieldValue('cpfCnpj', maskedValue);
                }}
                onBlur={formik.handleBlur}
                error={formik.errors.cpfCnpj}
              />
            </Box>

            <InputField
              mb="0px"
              id="telefone"
              label="Telefone/Celular"
              value={formik.values.telefone}
              onChange={(event: any) => {
                const maskedValue = maskTelefone(event.target.value);
                formik.setFieldValue('telefone', maskedValue);
              }}
              onBlur={formik.handleBlur}
              error={formik.errors.telefone}
            />

            <InputField
              id="endereco"
              mb="0px"
              label="Endereço"
              value={formik.values.endereco}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.errors.endereco}
            />
            <Box>
              <Flex direction="column" mb="34px">
                <FormLabel
                  htmlFor="Campanha"
                  fontSize="sm"
                  fontWeight="bold"
                  _hover={{ cursor: 'pointer' }}
                >
                  Campanha
                </FormLabel>
                <Select
                  fontSize="sm"
                  id="idCampanhaLead"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  rounded="xl"
                  h="44px"
                  maxH="44px"
                  color={textColorPrimary}
                  fontWeight="bold"
                ></Select>
              </Flex>
            </Box>
          </SimpleGrid>

          <Button
            isLoading={isLoading}
            variant="darkBrand"
            fontSize="sm"
            borderRadius="16px"
            w={{ base: '128px', md: '148px' }}
            h="46px"
            type="submit"
          >
            Cadastrar
          </Button>

          {isLoading && <Loading />}
        </Card>
      </FormControl>
    </form>
  );
}
