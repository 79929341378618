import { Flex, Image } from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';
import logoLeads from 'assets/svg/logo-leads.svg';

export function SidebarBrand(props: { mini: boolean; hovered: boolean }) {

  return (
    <Flex alignItems="center" flexDirection="column">
       <Image src={logoLeads}  h="26px"
        w="175px"
        my="32px" />   
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
