import {
  Box,
  Button,
  Flex,
  FormControl,
  SimpleGrid,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import Loading from 'components/loading';
import { useFormik } from 'formik';
import { useAuth } from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { ApiResponse } from 'types/api-response';
import * as Yup from 'yup';

export default function RegisterCampanha() {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if(!user.isCordenador){
      navigate('/admin/leads/lista');
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      nome: '',
      dataVigenciaInicio: '',
      dataVigenciaFim: '',
    },
    validationSchema: Yup.object({
      nome: Yup.string().required('Obrigatório'),
      dataVigenciaInicio: Yup.string()
        .required('Obrigatório'),
      dataVigenciaFim: Yup.string().required('Obrigatório'),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);

      const { nome, dataVigenciaInicio, dataVigenciaFim } = values;

      const jsonEnvio = {
        nome,
        dataVigenciaInicio: dataVigenciaInicio,
        dataVigenciaFim: dataVigenciaFim,
      };

      try {
        const response: AxiosResponse<ApiResponse> = await api.post(
          'CampanhaLeads',
          jsonEnvio,
        );

        if (response.data.isSuccess) {
          toast({
            title: `Campanha cadastrada com sucesso`,
            status: 'success',
            position: 'top-right',
            isClosable: true,
          });

          resetForm();
        }

        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);

        toast({
          title: `Ocorreu um erro`,
          description: error.join('\n'),
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
    },
  });
  return (
    <Box pt={{ base: '150px', md: '90px', xl: '90px' }}>
      <Flex direction="column" alignItems="center">
        <Box w="70%">
          <form onSubmit={formik.handleSubmit} noValidate>
            <FormControl isInvalid={formik.errors.nome && formik.touched.nome} display="flex" justifyContent="center">
              <Card mb="20px" w="50%" >
                <Flex direction="column" mb="30px" ms="10px">
                  <Text
                    fontSize="xl"
                    color={textColorPrimary}
                    fontWeight="bold"
                  >
                    Cadastro de campanha
                  </Text>
                </Flex>
                <SimpleGrid
                  columns={{ sm: 1, md: 1 }}
                  spacing={{ base: '20px', xl: '20px' }}
                >
                    <InputField
                      mb="10px"
                      me="30px"
                      id="nome"
                      
                      label="Nome"
                      value={formik.values.nome}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.nome}
                    />
                      <InputField
                        mb="10px"
                        me="30px"
                        id="dataVigenciaInicio"
                        label="Data início da vigência"
                        type="date"
                        max="9999-12-31T23:59"
                        value={formik.values.dataVigenciaInicio}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.dataVigenciaInicio}
                      />
                      <InputField
                        mb="10px"
                        me="30px"
                        id="dataVigenciaFim"
                        label="Data fim da vigência"
                        type="date"
                        max="9999-12-31T23:59"
                        value={formik.values.dataVigenciaFim}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.dataVigenciaFim}
                      />
                </SimpleGrid>
                <Button
                  isLoading={isLoading}
                  variant="darkBrand"
                  fontSize="sm"
                  borderRadius="16px"
                  w={{ base: '128px', md: '148px' }}
                  h="46px"
                  mt="20px"
                  type="submit"
                >
                  Cadastrar
                </Button>
                {isLoading && <Loading />}
              </Card>
            </FormControl>
          </form>
        </Box>
      </Flex>
    </Box>
  );
}
