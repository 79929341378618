import { NavLink } from 'react-router-dom';

// Chakra imports
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Link,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';

// Custom components
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import { SidebarContext } from 'contexts/SidebarContext';
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';

// Assets
import routes from 'routes';
import logoLeads from 'assets/svg/logo-leads.svg';
import { useAuth } from 'hooks/useAuth';

export default function AuthNavbar(props: {
  logo?: JSX.Element | string;
  logoText?: string;
  secondary?: boolean;
  sidebarWidth?: any;
}) {
  const { sidebarWidth } = props;
  // Menu States

  const {
    isOpen: isOpenLead,
    onOpen: onOpenLead,
    onClose: onCloseLead,
  } = useDisclosure();

  // Chakra color mode

  let mainText = useColorModeValue('navy.700', 'white');
  let navbarShadow = 'base';
  let navbarPosition = 'absolute' as 'absolute';

  const { user } = useAuth();

  let brand = (
    <Link
      href={`${process.env.PUBLIC_URL}/#/`}
      display="flex"
      lineHeight="100%"
      fontWeight="bold"
      justifyContent="center"
      alignItems="center"
      color={mainText}
    >
      <Stack
        direction="row"
        spacing="12px"
        alignItems="center"
        justify="center"
      >
        <Image src={logoLeads} />
      </Stack>
    </Link>
  );

  const linkCadastrarLead = (
    <HStack display={{ sm: 'none', lg: 'flex' }} spacing="20px">
      <Stack
        direction="row"
        spacing="4px"
        alignItems="center"
        color="#fff"
        fontWeight="bold"
        onMouseEnter={onOpenLead}
        onMouseLeave={onCloseLead}
        cursor="pointer"
        position="relative"
      >
        <Button colorScheme="teal" variant="ghost">
          <NavLink to={'/admin' + '/leads/lista'}>
            <Text fontSize="sm" color={mainText}>
              Lista
            </Text>
          </NavLink>
        </Button>
      </Stack>
      <Stack
        direction="row"
        spacing="4px"
        alignItems="center"
        color="#fff"
        fontWeight="bold"
        onMouseEnter={onOpenLead}
        onMouseLeave={onCloseLead}
        cursor="pointer"
        position="relative"
      >
        <Button colorScheme="teal" variant="ghost">
          <NavLink to={'/admin' + '/leads/register'}>
            <Text fontSize="sm" color={mainText}>
              Cadastrar
            </Text>
          </NavLink>
        </Button>
      </Stack>
      {user.isCordenador && (
        <>
          <Stack
            direction="row"
            spacing="4px"
            alignItems="center"
            color="#fff"
            fontWeight="bold"
            onMouseEnter={onOpenLead}
            onMouseLeave={onCloseLead}
            cursor="pointer"
            position="relative"
          >
            <Button colorScheme="teal" variant="ghost">
              <NavLink to={'/admin' + '/arquivos/importar'}>
                <Text fontSize="sm" color={mainText}>
                  Importar
                </Text>
              </NavLink>
            </Button>
          </Stack>
          <Stack
            direction="row"
            spacing="4px"
            alignItems="center"
            color="#fff"
            fontWeight="bold"
            onMouseEnter={onOpenLead}
            onMouseLeave={onCloseLead}
            cursor="pointer"
            position="relative"
          >
            <Button colorScheme="teal" variant="ghost">
              <NavLink to={'/admin' + '/arquivos/lista'}>
                <Text fontSize="sm" color={mainText}>
                  Importações
                </Text>
              </NavLink>
            </Button>
          </Stack>
          <Stack
            direction="row"
            spacing="4px"
            alignItems="center"
            color="#fff"
            fontWeight="bold"
            onMouseEnter={onOpenLead}
            onMouseLeave={onCloseLead}
            cursor="pointer"
            position="relative"
          >
            <Button colorScheme="teal" variant="ghost">
              <NavLink to={'/admin' + '/campanha/register'}>
                <Text fontSize="sm" color={mainText}>
                  Cadastrar Campanha
                </Text>
              </NavLink>
            </Button>
          </Stack>
          <Stack
            direction="row"
            spacing="4px"
            alignItems="center"
            color="#fff"
            fontWeight="bold"
            onMouseEnter={onOpenLead}
            onMouseLeave={onCloseLead}
            cursor="pointer"
            position="relative"
          >
            <Button colorScheme="teal" variant="ghost">
              <NavLink to={'/admin' + '/campanha/lista'}>
                <Text fontSize="sm" color={mainText}>
                  Campanhas
                </Text>
              </NavLink>
            </Button>
          </Stack>
        </>
      )}
    </HStack>
  );

  const iconColor = useColorModeValue('white', 'white');
  return (
    <SidebarContext.Provider value={{ sidebarWidth }}>
      <Flex
        position={navbarPosition}
        top="16px"
        left="50%"
        transform="translate(-50%, 0px)"
        bgGradient="linear(to-b, brand.400, brand.600)"
        boxShadow={navbarShadow}
        bg={iconColor}
        borderRadius="15px"
        px="16px"
        py="20px"
        mx="auto"
        width="97%"
        maxW="97%"
        alignItems="center"
        zIndex="2"
      >
        <Flex w="100%" justifyContent={{ sm: 'start', lg: 'none' }}>
          {brand}
          <Box pl="20px">
            <SidebarResponsive routes={routes} />
          </Box>
          <Box
            ms={{ base: 'auto', lg: '10px' }}
            justifyContent="center"
            alignItems="center"
          >
            {linkCadastrarLead}
          </Box>
          <Box
            ms={{ base: 'auto', lg: '0px' }}
            display={{ base: 'flex', lg: 'none' }}
            justifyContent="center"
            alignItems="center"
          ></Box>
        </Flex>
        <Flex w="100%" justifyContent={{ sm: 'start', lg: 'none' }}>
          <Box ms="auto" w={{ sm: '100%', md: 'unset' }}>
            <AdminNavbarLinks secondary={props.secondary} />
          </Box>
        </Flex>
      </Flex>
    </SidebarContext.Provider>
  );
}
