import { Flex, FormLabel, Input, Text, useColorModeValue } from '@chakra-ui/react';

export default function InputField(props: {
  id?: string;
  label?: string;
  extra?: JSX.Element;
  placeholder?: string;
  type?: string;
  error?: string; // Modifique para receber uma string que representa a mensagem de erro
  mb?: string;
  [x: string]: any;
}) {
  const { id, label, extra, placeholder, type, error, mb, ...rest } = props;
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Flex direction='column' mb={mb ? mb : '30px'}>
      <FormLabel
        display='flex'
        ms='10px'
        htmlFor={id}
        fontSize='sm'
        color={textColorPrimary}
        fontWeight='bold'
        _hover={{ cursor: 'pointer' }}>
        {label}
        <Text fontSize='sm' fontWeight='400' ms='2px'>
          {extra}
        </Text>
      </FormLabel>
      <Input
        {...rest}
        type={type}
        id={id}
        fontWeight='500'
        variant='main'
        placeholder={placeholder}
        _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
        h='44px'
        maxH='44px'
        borderColor={error ? 'red.300' : undefined} // Adicione a borda vermelha quando houver um erro
        isInvalid={!!error} // Marque o input como inválido quando houver um erro
      />
      {error && (
        <Text fontSize='sm' color='red.300' mt='2px' ml='10px'>
          {error}
        </Text>
      )}
    </Flex>
  );
}
