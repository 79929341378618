import { validaCPF } from './validaInputsRegister';

export const validarCPFCNPJ = (cpf: string) => {
  if (cpf.length <= 14) {
    if (!validaCPF(cpf)) {
      return false;
    }
  }

  if (cpf.length > 14 && cpf.length < 18) {
    return false;
  }

  return true;
};
