import React from 'react';
import { Spinner, Box } from '@chakra-ui/react';

interface LoadingProps {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
}

const Loading: React.FC<LoadingProps> = ({ size = 'xl', color = 'primary.500' }) => {
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      backgroundColor="rgba(0, 0, 0, 0.5)"
      display="flex"
      justifyContent="center"
      alignItems="center"
      zIndex="9999"
      backdropFilter="blur(5px)" // Adicione o fundo desfocado
    >
      <Spinner size={size} color={color} />
    </Box>
  );
};

export default Loading;