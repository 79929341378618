// Chakra Imports
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import MenuUser from 'components/menu/UserMenu';
import { useAuth } from 'hooks/useAuth';

// Assets
export default function HeaderLinks(props: {
  secondary: boolean;
  [x: string]: any;
}) {
  const { secondary } = props;
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const { user } = useAuth();

  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      pt="0px"
      pb="0px"
      borderRadius="999px"
      shadow="base"
    >
      <Box pl="20px" pr="20px">
        <Text color={textColor} fontSize="md" fontWeight="700">
          {user.nome}
        </Text>
        <Text color="secondaryGray.600" fontSize="sm" fontWeight="400">
          {user.promotor?.nomeFantasiaFranquia ?? ""}
        </Text>
      </Box>
      <MenuUser />
    </Flex>
  );
}
