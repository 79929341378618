/* eslint-disable */ 

import { Avatar, Badge, Box, Button, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue,  Icon,} from '@chakra-ui/react';
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable
} from '@tanstack/react-table';
// Custom components  
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import * as React from 'react';
import { useEffect } from 'react';
// Assets 
import { MdMedicalInformation } from 'react-icons/md';
import { Leads } from 'types/import-leads';

type RowObj = {
  nome:string,
  cpfCnpj?:string,
  email:string,
  statusLead:string,
  telefone:string|number, 
};

const columnHelper = createColumnHelper<Leads>();

// const columns = columnsDataCheck;
export default function LastOfferTable(props: { tableData: any }) {
	const { tableData } = props;
	const [ sorting, setSorting ] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("navy.700", "white"); 
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	let defaultData = tableData;
	const columns = [
		columnHelper.accessor('nome', {
			id: 'nome',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					NOME
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Flex direction='column'>
						<Text color={textColor} fontSize='sm' fontWeight='700'>
							{info.getValue()}
						</Text>
					</Flex>
				</Flex>
			)
		}), 
		columnHelper.accessor('cpfCnpj', {
			id: 'cpfCnpj',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					CPF
				</Text>
			),
			cell: (info) => ( 
				<Text color={textColor} fontSize='sm' fontWeight='600'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('email', {
			id: 'email',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					EMAIL
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='sm' fontWeight='600'>
					{info.getValue()}
				</Text>
			)
		}),
		
		columnHelper.accessor('telefone', {
			id: 'telefone',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Telefone
				</Text>
			),
			cell: (info) => ( 
				<Text color={textColor} fontSize='sm' fontWeight='600'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('motivoRejeicao', {
			id: 'motivoRejeicao',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Motivo
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Flex direction='column'>
						<Text color={textColor} fontSize='sm' fontWeight='700'>
							{info.getValue()}
						</Text>
					</Flex>
				</Flex>
			)
		}), 
	];
	const [ data, setData ] = React.useState(() => [ ...defaultData ]);
	useEffect(() => {
		setData(defaultData)
	  }, [defaultData]);
	const table = useReactTable({
		data,
		columns,
		state: {
			sorting
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		debugTable: true
	});
	return (
		<Card>
			<Flex direction='column' w='100%' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
				<Flex align={{ lg: 'center' }} justify={{ base: 'space-between' }} w='100%' px='20px' mb='20px'>
					<Text color={textColor} fontSize='xl' fontWeight='600' lineHeight='100%'>
						Leads Rejeitados
					</Text>
				</Flex>
				<Box>
					<Table variant='simple' color='gray.500'  mt="12px">
						<Thead>
							{table.getHeaderGroups().map((headerGroup) => (
								<Tr key={headerGroup.id}>
									{headerGroup.headers.map((header) => {
										return (
											<Th
												key={header.id}
												colSpan={header.colSpan}
												pe='10px'
												borderColor={borderColor}
												cursor='pointer'
												onClick={header.column.getToggleSortingHandler()}>
												<Flex
													justifyContent='space-between'
													align='center'
													fontSize={{ sm: '10px', lg: '12px' }}
													color='gray.400'>
													{flexRender(header.column.columnDef.header, header.getContext())}{{
														asc: '',
														desc: '',
													}[header.column.getIsSorted() as string] ?? null}
												</Flex>
											</Th>
										);
									})}
								</Tr>
							))}
						</Thead>
						<Tbody>
							{table.getRowModel().rows.slice(0, 11).map((row) => {
								return (
									<Tr key={row.id}>
										{row.getVisibleCells().map((cell) => {
											return (
												<Td
													key={cell.id}
													fontSize={{ sm: '14px' }}
													minW={{ sm: '150px', md: '200px', lg: 'auto' }}
													borderColor='transparent'>
													{flexRender(cell.column.columnDef.cell, cell.getContext())}
												</Td>
											);
										})}
									</Tr>
								);
							})}
						</Tbody>
					</Table>
				</Box>
			</Flex>
		</Card>
	);
}
  