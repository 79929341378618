// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Select,
  SimpleGrid,
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useTheme,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import TagsField from 'components/fields/TagsField';
import Dropzone from './components/Dropzone';
import React, { useCallback, useEffect, useState } from 'react';

// Assets
import { MdOutlineCloudUpload } from 'react-icons/md';
import { useDropzone } from 'react-dropzone';
import api from 'services/api';
import { AxiosError, AxiosResponse } from 'axios';
import { Iplanilha, IresultImport, Leads } from 'types/import-leads';

import LeadsImportTable from './components/LeadsImportTable';
import { ApiResponse } from 'types/api-response';
import { useNavigate } from 'react-router-dom';
import { maskCpfCnpj, maskTelefone } from 'util/maskInputs';
import { apiReturn } from 'types/data-response';
import { Campanha } from 'views/campanha/lista/hooks/fetchDataCampanha';
import { useAuth } from 'hooks/useAuth';

export default function NewProduct() {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const mediaTab = React.useRef() as React.MutableRefObject<HTMLButtonElement>;
  const envioTab = React.useRef() as React.MutableRefObject<HTMLButtonElement>;
  const theme = useTheme();
  const toast = useToast();
  const navigate = useNavigate();
  //eslint-disable-next-line
  const [lineColor, setLineColor] = useState(theme.colors.brand[500]);
  //eslint-disable-next-line
  const [lineColorDark, setLineColorDark] = useState(theme.colors.brand[400]);
  const brand = useColorModeValue(lineColor, lineColorDark);
  const [files, setFiles] = useState(null);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [activeBullets, setActiveBullets] = useState({
    media: true,
    envio: false,
  });
  const [errorPlanilha, setErrorPlanilha] = useState<string[]>([]);
  const [planilha, setPlanilha] = useState<Iplanilha>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tableLeads, setTableLeads] = useState<Leads[]>([]);
  const [idCampanha, setIdCampanha] = useState('');

  const { user } = useAuth();
  useEffect(() => {
    if(!user.isCordenador){
      navigate('/admin/leads/lista');
    }
  }, []);

  const receiveFileFromChild = (acceptedFiles: File) => {
    setFiles(acceptedFiles);
    if (acceptedFiles === null) {
      setFiles(null);
      setErrorPlanilha([]);
      setPlanilha(null);
      setTableLeads([]);
    }
  };

  const handleClickSendFile = async () => {
    try {
      const JsonEnvio = {
        idPlanilha: planilha.idPlanilha,
      };

      const response: AxiosResponse<ApiResponse> = await api.post(
        `ImportacoesLeads/FinalizarImportacao`,
        JsonEnvio,
      );

      if (response.data.isSuccess) {
        toast({
          title: `Leads enviados para processamento`,
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });
        receiveFileFromChild(null);
        navigate('/admin/leads/lista');
      }
    } catch (error: any) {
      toast({
        title: `Ocorreu um erro`,
        description: error.join('\n'),
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleClickSendValidFile = async () => {
    const jsonEnvio = new FormData();

    jsonEnvio.append('file', files[0]);

    const filterCampanha = idCampanha !== '' ? `?IdCampanhaLead=${idCampanha}` : '';

    try {
      const response: AxiosResponse<IresultImport> = await api.post(
        `ImportacoesLeads/IniciarImportacao${filterCampanha}`,
        jsonEnvio,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      setPlanilha(response.data.value);
      setTabelLeadsInvalidos(response.data.value.leadsInvalidos);
    } catch (error: any) {
      setIsLoading(false);

      const arrayError: string[] = error[0].split(';');

      setErrorPlanilha(arrayError);
    }
  };

  function setTabelLeadsInvalidos(leads: Leads[]) {
    var tableRow: Leads[] = [];

    leads.forEach((elemt) => {
      var row: Leads = {
        cpfCnpj: elemt.cpfCnpj ? maskCpfCnpj(elemt.cpfCnpj) : null,
        telefone: elemt.telefone ? maskTelefone(elemt.telefone) : null,
        email: elemt.email,
        nome: elemt.nome,
        motivoRejeicao: elemt.motivoRejeicao,
      };

      tableRow.push(row);
    });

    setTableLeads(tableRow);
  }

  function DisableButtonEnvio() {
    if (!planilha || !planilha.idPlanilha) return false;

    return true;
  }

  const alertsErros = errorPlanilha.map((err) => (
    <Alert borderRadius="8px" status="error" variant="solid">
      <AlertIcon />
      <AlertDescription key={err}>{err}</AlertDescription>
    </Alert>
  ));

  async function fatchCamapanhasOption() {
    try {
      const response: apiReturn = await api.get('CampanhaLeads/Vigentes');

      return response.data.items as Campanha[];
    } catch (error: any) {
      toast({
        title: `Ocorreu um erro`,
        description: error,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  async function populateCampanhaOptions() {
    const selectElement = document.getElementById('idCampanhaLead');
    const options = await fatchCamapanhasOption();

    selectElement.innerHTML = '';

    const optionElement = document.createElement('option');
    optionElement.value = '';
    optionElement.textContent = `Selecionar...`;
    selectElement.appendChild(optionElement);

    if (options.length > 0) {
      options.forEach((option) => {
        const optionElement = document.createElement('option');
        optionElement.value = option.id.toString();
        optionElement.textContent = `${option.id} - ${option.nome}`;
        selectElement.appendChild(optionElement);
      });
    }
  }

  useEffect(() => {
    populateCampanhaOptions();
  }, []);

  const alertsPlanilhas = (
    <>
      {planilha && (
        <Flex flexDirection="column" gap="3px">
          <Alert borderRadius="8px" status="success" variant="solid">
            <AlertIcon />
            <AlertDescription>{planilha.feedbackPlanilha}</AlertDescription>
          </Alert>
          <Flex>
            {planilha && (
              <Alert
                status="success"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                height="150px"
                width="400px"
              >
                <AlertIcon boxSize="30px" mr={0} />
                <AlertTitle mt={4} mb={1} fontSize="lg">
                  Leads Validos
                </AlertTitle>
                <AlertDescription maxWidth="sm">
                  {planilha.totalLeadsValidos ?? 0}
                </AlertDescription>
              </Alert>
            )}
            {planilha && (
              <Alert
                status="error"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                height="150px"
                width="400px"
              >
                <AlertIcon boxSize="30px" mr={0} />
                <AlertTitle mt={4} mb={1} fontSize="lg">
                  Leads Invalidos
                </AlertTitle>
                <AlertDescription fontSize="lg">
                  {planilha.totalLeadsInvalidos ?? 0}
                </AlertDescription>
              </Alert>
            )}
          </Flex>
        </Flex>
      )}
    </>
  );

  const handleDownloadFileModel = () => {
    const link = document.createElement('a');
    link.download = 'Importação de leads 360º';

    link.href = '/documents/Importaçãodeleads.xlsx';

    link.click();
  };

  return (
    <>
      <Flex
        direction="column"
        minH="100vh"
        align="center"
        pt={{ sm: '125px', lg: '75px' }}
        position="relative"
      >
        <Box
          h="45vh"
          bgGradient="linear(to-b, brand.400, brand.600)"
          position="absolute"
          w="100%"
          borderRadius="20px"
        />

        <Tabs
          variant="unstyled"
          mt={{ base: '60px', md: '165px' }}
          zIndex="0"
          display="flex"
          flexDirection="column"
        >
          <TabList
            display="flex"
            alignItems="center"
            alignSelf="center"
            justifySelf="center"
          >
            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={mediaTab}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
              onClick={() =>
                setActiveBullets({
                  media: true,
                  envio: false,
                })
              }
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: '120px', md: '250px', lg: '300px' },
                  height: '3px',
                  bg: activeBullets.envio ? 'white' : 'brand.400',
                  left: { sm: '12px', md: '40px' },
                  top: {
                    sm: activeBullets.envio ? '6px' : '4px',
                    md: null,
                  },
                  position: 'absolute',
                  bottom: activeBullets.envio ? '40px' : '38px',

                  transition: 'all .3s ease',
                }}
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.media ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.media ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.media ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  Importar
                </Text>
              </Flex>
            </Tab>
            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={envioTab}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
              onClick={() =>
                setActiveBullets({
                  media: true,
                  envio: true,
                })
              }
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.envio ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.envio ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.envio ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  Envio
                </Text>
              </Flex>
            </Tab>
          </TabList>
          <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text
                  color={textColor}
                  fontSize="2xl"
                  fontWeight="700"
                  mb="20px"
                >
                  Arquivo
                </Text>
                <Dropzone
                  sendFileToParent={receiveFileFromChild}
                  content={
                    <Box>
                      <Icon
                        as={MdOutlineCloudUpload}
                        w="80px"
                        h="80px"
                        color={textColor}
                      />
                      <Text
                        mx="auto"
                        mb="12px"
                        fontSize="lg"
                        fontWeight="700"
                        whiteSpace="pre-wrap"
                        color={textColor}
                      >
                        Solte seu arquivo aqui ou{' '}
                        <Text
                          as="span"
                          fontSize="lg"
                          fontWeight="700"
                          color={brand}
                        >
                          buscar
                        </Text>
                      </Text>
                      <Text
                        fontSize="sm"
                        fontWeight="500"
                        color="secondaryGray.500"
                      >
                        Apenas um arquivo XLSX será permitido(Max 100 Leads)
                      </Text>
                    </Box>
                  }
                />
                <Flex justify="space-between" mt="24px" alignItems={"flex-end"}>
                  <Button
                    variant="darkBrand"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    onClick={handleDownloadFileModel}
                  >
                    Baixar modelo
                  </Button>
                  <Box>
                    <FormLabel
                      htmlFor="Campanha"
                      fontSize="sm"
                      fontWeight="bold"
                    >
                      Campanha
                    </FormLabel>
                    <Select
                      fontSize="sm"
                      id="idCampanhaLead"
                      value={idCampanha}
                      onChange={(event) => {
                        setIdCampanha(event.target.value);
                      }}
                      w={{ base: '350px', md: '350px' }}
                      rounded="xl"
                      h="44px"
                      maxH="44px"
                      color={textColorPrimary}
                      _hover={{ cursor: 'pointer' }}
                      fontWeight="bold"
                    ></Select>
                  </Box>
                  <Button
                    variant="darkBrand"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    
                    isDisabled={!files}
                    _disabled={{
                      opacity: '0.5',
                    }}
                    _hover={!files && {}}
                    onClick={() => {
                      envioTab.current.click();
                      handleClickSendValidFile();
                      setActiveBullets({
                        media: true,
                        envio: true,
                      });
                    }}
                  >
                    Validar
                  </Button>
                </Flex>
              </Card>
            </TabPanel>
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text
                  color={textColor}
                  fontSize="2xl"
                  fontWeight="700"
                  mb="20px"
                >
                  Envio
                </Text>
                <Flex flexDirection="column" gap="3px">
                  {alertsErros}
                </Flex>
                {alertsPlanilhas}
                <Flex justify="space-between" mt="24px">
                  <Button
                    variant="light"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    onClick={() => {
                      mediaTab.current.click();
                      setActiveBullets({
                        media: true,
                        envio: false,
                      });
                    }}
                  >
                    Voltar
                  </Button>
                  <Button
                    variant="darkBrand"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    isDisabled={!DisableButtonEnvio()}
                    _disabled={{
                      opacity: '0.5',
                    }}
                    _hover={!DisableButtonEnvio() && {}}
                    onClick={handleClickSendFile}
                  >
                    Enviar
                  </Button>
                </Flex>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>

        {planilha &&
        planilha.leadsInvalidos.length > 0 &&
        activeBullets.envio ? (
          <Flex width="90%" mt="15px">
            <LeadsImportTable tableData={tableLeads} />
          </Flex>
        ) : (
          <></>
        )}
      </Flex>
    </>
  );
}
