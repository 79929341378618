export function maskCpfCnpj(cpfCnpj: string): string {
  var cpfCnpjInput = cpfCnpj.replace(/\D/g, '');

  if (cpfCnpjInput.length <= 11) {
    if (cpfCnpjInput.length > 3) {
      cpfCnpjInput =
        cpfCnpjInput.substring(0, 3) + '.' + cpfCnpjInput.substring(3);
    }
    if (cpfCnpjInput.length > 7) {
      cpfCnpjInput =
        cpfCnpjInput.substring(0, 7) + '.' + cpfCnpjInput.substring(7);
    }
    if (cpfCnpjInput.length > 11) {
      cpfCnpjInput =
        cpfCnpjInput.substring(0, 11) + '-' + cpfCnpjInput.substring(11);
    }
  } else {
    if (cpfCnpjInput.length > 2) {
      cpfCnpjInput =
        cpfCnpjInput.substring(0, 2) + '.' + cpfCnpjInput.substring(2);
    }
    if (cpfCnpjInput.length > 6) {
      cpfCnpjInput =
        cpfCnpjInput.substring(0, 6) + '.' + cpfCnpjInput.substring(6);
    }
    if (cpfCnpjInput.length > 10) {
      cpfCnpjInput =
        cpfCnpjInput.substring(0, 10) + '/' + cpfCnpjInput.substring(10);
    }
    if (cpfCnpjInput.length > 15) {
      cpfCnpjInput =
        cpfCnpjInput.substring(0, 15) + '-' + cpfCnpjInput.substring(15);
    }
  }

  return cpfCnpjInput;
}

export function maskTelefone(telefone: string): string {
  telefone = telefone.replace(/\D/g, '');

  if (telefone.length <= 10) {
    if (telefone.length > 0) {
      telefone = '(' + telefone.substring(0);
    }
    if (telefone.length > 3) {
      telefone = telefone.substring(0, 3) + ') ' + telefone.substring(3);
    }
    if (telefone.length > 10) {
      telefone = telefone.substring(0, 9) + '-' + telefone.substring(9);
    }
  } else {
    if (telefone.length > 0) {
      telefone = '(' + telefone.substring(0);
    }
    if (telefone.length > 3) {
      telefone = telefone.substring(0, 3) + ') ' + telefone.substring(3);
    }
    if (telefone.length > 9) {
      telefone = telefone.substring(0, 10) + '-' + telefone.substring(10);
    }
    if (telefone.length > 15) {
      telefone = telefone.substring(0, 15);
    }

    return telefone;
  }

  return telefone;
}

export function formatarData(dataString: string) {
  const data = new Date(dataString);
  
  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0'); // Note que os meses em JavaScript são base 0, então é necessário adicionar 1.
  const ano = data.getFullYear();
  const hora = String(data.getHours()).padStart(2, '0');
  const minutos = String(data.getMinutes()).padStart(2, '0');
  
  return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
}