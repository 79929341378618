

export function validaCPF(cpf: string): boolean {
    if (!cpf) return false;

    let d1, d2;
    let soma = 0;
    let digitado = "";
    let calculado = "";

    // Pesos para calcular o primeiro digito
    const peso1 = [10, 9, 8, 7, 6, 5, 4, 3, 2];

    // Pesos para calcular o segundo digito
    const peso2 = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2];
    const n = new Array(11);

    cpf = cpf.replace(/\D/g, ''); // Remove caracteres não numéricos
    // Se o tamanho for diferente de 11, retorna como inválido
    if (cpf.length !== 11) return false;

    // Caso todos os números sejam iguais
    if (/^(\d)\1{10}$/.test(cpf)) return false;

    try {
        // Quebra cada dígito do CPF
        for (let i = 0; i < 11; i++) {
            n[i] = parseInt(cpf.charAt(i), 10);
        }
    } catch (e) {
        return false;
    }

    // Calcula o primeiro dígito com seu respectivo peso
    for (let i = 0; i < peso1.length; i++) {
        soma += peso1[i] * n[i];
    }

    // Pega o resto da divisão
    const resto = soma % 11;

    if (resto === 1 || resto === 0) {
        d1 = 0;
    } else {
        d1 = 11 - resto;
    }

    soma = 0;

    // Calcula o segundo dígito com seu respectivo peso
    for (let i = 0; i < peso2.length; i++) {
        soma += peso2[i] * n[i];
    }

    // Pega o resto da divisão
    const resto2 = soma % 11;

    if (resto2 === 1 || resto2 === 0) {
        d2 = 0;
    } else {
        d2 = 11 - resto2;
    }

    calculado = d1.toString() + d2.toString();
    digitado = n[9].toString() + n[10].toString();

    // Se os últimos dois dígitos calculados baterem com
    // os dois últimos dígitos do CPF, então é válido
    return calculado === digitado;
}


export function validarTelefone(telefone: string) {
    if (telefone.length < 14) {
        return false;
    }

    if(telefone[5] === '9' && telefone.length < 15){
        return false;
    }
  
    return true;
  };

  export function validarEmail(email: string): boolean {
    const padrao = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if(padrao.test(email)){
        return true;
    }

    return false
  
  }