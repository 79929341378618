// Chakra imports
import { Button, Flex, Icon, useColorModeValue, Box } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  MdRemoveCircle,
  MdRemove,
  MdAttachFile,
  MdInsertDriveFile,
  MdFileUpload,
  MdOutlineInsertDriveFile,
} from 'react-icons/md';
// Assets

function Dropzone(props: {
  sendFileToParent: any;
  content: JSX.Element | string;
  [x: string]: any;
}) {
  const { sendFileToParent, content, ...rest } = props;

  const [myFiles, setMyFiles] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setMyFiles([...myFiles, ...acceptedFiles]);
      sendFileToParent([...myFiles, ...acceptedFiles]);
    },
    [myFiles],
  );

  const removeFile = (file: File) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
    sendFileToParent(null);
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
    },
    onDrop: onDrop,
    maxFiles: 1,
  });

  const files = myFiles.map((file) => (
    <Flex direction='column' align='center'>
      <Box >
      <Button position="absolute" p="0px" h="max-content" onClick={removeFile(file)}>
        <Icon h="25px" w="25px" color="red" as={MdRemove} />
      </Button>
      <Icon
        me="10px"
        h="100px"
        w="100px"
        as={MdInsertDriveFile}
        color="green.500"
      />
      </Box>
      
      <Box h='fit-content' key={file.name}>
        {file.path} - {file.size} bytes{' '}
      </Box>
    </Flex>
  ));

  const bg = useColorModeValue('gray.100', 'navy.700');
  const borderColor = useColorModeValue('gray.300', 'whiteAlpha.100');

  return (
    <>
      <Flex
        align="center"
        justify="center"
        bg={bg}
        border="1px dashed"
        borderColor={borderColor}
        borderRadius="16px"
        w="100%"
        maxW="100%"
        h="max-content"
        minH="130px"
        cursor="pointer"
        {...(myFiles.length === 0 && getRootProps({ className: 'dropzone' }))}
        pt={(myFiles.length === 0 ? "80px !important" : "40px !important" )}
        pb={(myFiles.length === 0 ? "105px !important" : "60px !important" )}
        {...rest}
      >
        {myFiles.length > 0 ? (
          files
        ) : (
          <>
            <input {...getInputProps()} />
            <Button variant="no-effects">{content}</Button>{' '}
          </>
        )}
      </Flex>
    </>
  );
}

export default Dropzone;
