import {
  Badge,
  Button,
  Flex,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useEffect, useState } from 'react';
import api from 'services/api';
import SearchTableOverview from 'views/leads/lista/components/SearchTableOverview';
import { createColumnHelper } from '@tanstack/react-table';
import {  MdInfo } from 'react-icons/md';
import { format } from 'date-fns';
import { Campanha, fetchDataCampanha } from './hooks/fetchDataCampanha';
import { AxiosResponse } from 'axios';
import { ApiResponse } from 'types/api-response';
import AlertDialogPopUp from 'components/alertDialog/AlertDialog';
import { useAuth } from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';

export default function ListaCampanha() {
  const textColor = useColorModeValue('navy.700', 'white');
  const toast = useToast();

  const { user } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if(!user.isCordenador){
      navigate('/admin/leads/lista');
    }
  }, []);

  const [selectedCamapanha, setSelectedCamapanha] = useState<Campanha|null>(null);
  const [reloadTable, setReloadTabel] = useState<boolean>(false);
  const columnHelper = createColumnHelper<Campanha>();
  const columns = [
     columnHelper.accessor('nome', {
       id: 'nome',
       header: () => (
         <Text
           justifyContent="space-between"
           align="center"
           fontSize={{ sm: '10px', lg: '12px' }}
           color="gray.400"
         >
           Nome
         </Text>
       ),
       cell: (info: any) => (
         <Text color={textColor} fontSize="sm" fontWeight="700">
           {info.getValue() ?? ""}
         </Text>
       ),
     }),
    columnHelper.accessor('dataVigenciaInicio', {
      id: 'dataVigenciaInicio',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Início vigência
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()
            ? format(new Date(info.getValue()), 'dd/MM/yyyy')
            : info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('dataVigenciaFim', {
      id: 'dataVigenciaFim',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Fim vigência
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="600">
          {info.getValue()
            ? format(new Date(info.getValue()), 'dd/MM/yyyy')
            : info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          STATUS
        </Text>
      ),
      cell: (info) => (
        <Badge
          colorScheme={
            info.getValue() === 'INATIVO'
              ? 'red'
              : 'green'
          }
          color={
            info.getValue() === 'INATIVO'
              ? 'red.500'
              : 'green.500'
          }
          fontSize="sm"
          fontWeight="600"
        >
          {info.getValue() ? info.getValue().toLowerCase() : ''}
        </Badge>
      ),
    }),
    columnHelper.accessor('id', {
      id: 'id',
      header: () => <Text></Text>,
      cell: (info) => (
        <Flex cursor="pointer" h="max-content" w="max-content">
          <Button
            leftIcon={<MdInfo />}
            colorScheme="red"
            variant="solid"
            onClick={() => {
              setSelectedCamapanha(info.row.original)
            }}
          >
            Cancelar
          </Button>
        </Flex>
      ),
    }),
  ];

  async function handlerInativarCampanha(){
    try {
      const response: AxiosResponse<ApiResponse> = await api.delete(
        `CampanhaLeads/${selectedCamapanha.id}`
      );

      if (response.data.isSuccess) {
        toast({
          title: `Campanha desativada com sucesso`,
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });
      }
      setReloadTabel(!reloadTable);
      setSelectedCamapanha(null);
    } catch (error: any) {
      toast({
        title: `Ocorreu um erro`,
        description: error.join('\n'),
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
      setSelectedCamapanha(null)
    }
  }




  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
      <Card px="0px">
        <SearchTableOverview
          fetchData={fetchDataCampanha}
          columns={columns}
          showDatePicker
          showFilter
          reloadData={reloadTable}
        />
        {selectedCamapanha && (
          <AlertDialogPopUp open={selectedCamapanha != null} confirmFunction={handlerInativarCampanha} 
          cancelFunction={()=>{setSelectedCamapanha(null)}} textHeader='Cancelar Campanha' 
          textBody={`Tem certeza que deseja cancelar a camapanha ${selectedCamapanha.nome.toUpperCase()}`} />
        )}
      </Card>
    </Flex>
  );
}
