import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { User } from '../interfaces/user.model';
import { AuthService } from '../auth/AuthService';
import api from 'services/api';
import { AxiosResponse } from 'axios';
import { validaAcesso } from 'types/validaAcesso';

interface AuthContext {
  isAuthenticated: boolean;
  loading: boolean;
  user: User;
  legacyFranchisorId: string;
  logout: () => void;
}

const Context = React.createContext<AuthContext>({} as AuthContext);
let loaddingUserInfo = false;
export const UseAuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [legacyFranchisorId, setLegacyFranchisorId] = useState(
    process.env.NEXT_PUBLIC_FIND_SALES_PROMOTER_FRANCHISE_ID || '',
  );
  const [user, setUser] = useState<User>(User.createNullUser());

  const authService = new AuthService({
    clientId: `${process.env.REACT_APP_SSO_CLIENT_ID}`,
    provider: `${process.env.REACT_APP_SSO_BASE_URL}/connect`,
    redirectUri: `${process.env.REACT_APP_SSO_REDIRECT_URI}`,
    scopes: ['openid', 'profile', 'ctn', 'apilead360'],
    durableLogin: process.env.REACT_APP_SSO_DURABLE_LOGIN === 'true',
    clientSecret: process.env.REACT_APP_SSO_CLIENT_SECREAT,
  });

  useEffect(() => {
    const logout = async () => authService.logout();

    const login = async () => {
      logout();
      await authService.authorize();
    };

    async function loadUserInfo(): Promise<void> {
      if (loaddingUserInfo) return;
      loaddingUserInfo = true;
      await authService.loadDataFromCodeIfNeeded();

      if (!authService.isAuthenticated()) {
        await login();
        setIsAuthenticated(false);
        return;
      }

      const idToken = authService.getUser();

      if (idToken) {
        const validaAcesso = await fetchValidaAcesso();
        
        const isCordenador = validaAcesso.erro === true ? false : verificaAcesso(validaAcesso);

        setUser(User.createFromIDToken(idToken, validaAcesso, isCordenador));
      }

      setIsAuthenticated(true);
    }

    loadUserInfo();
  }, []);

  async function fetchValidaAcesso(): Promise<validaAcesso> {
    try {
      const response: AxiosResponse<validaAcesso> = await api.get(
        `ValidacaoAcesso`,
      );

      return response.data;
    } catch (error: any) {
      var validaAcesso: validaAcesso = {
        mensagem: error,
        promotor: null,
        rolesExigidasParaAcessoCordenador: [],
        exigirAcessoCordenador: true,
        erro: true,
      }

      return validaAcesso
    }
  }

  function verificaAcesso(validaAcesso: validaAcesso): boolean {

    if ( validaAcesso.exigirAcessoCordenador === false) {
      return true;
    }

    const temAcesso = validaAcesso.promotor.listaDeRolesPromotor.some((role) =>
      validaAcesso.rolesExigidasParaAcessoCordenador.includes(role),
    );

    return temAcesso;
  }

  const logout = async () => {
    setIsAuthenticated(false);

    await authService.logout(true);
  };

  const value = useMemo(
    () => ({
      isAuthenticated,
      loading: loaddingUserInfo,
      user,
      legacyFranchisorId,
      logout,
    }),
    [isAuthenticated, user, legacyFranchisorId],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useAuth = () => useContext(Context);
