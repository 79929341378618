import api from 'services/api';
import { apiReturn } from 'types/data-response';
import { filterTable } from 'views/leads/lista/components/SearchTableOverview';
import { leads } from 'views/leads/lista/hooks/fetchDataLeads';



export interface Files{
  caminhoArquivoNuvem: string;
  dataCadastro: string;
  dataProcessamento: string;
  id: number;
  idPromotorVendas: number;
  leads: null|Array<leads>;
  status: string
  nomeArquivo: string
}

  export async function fetchDataFiles(options: filterTable) {

    var filter = ''

    if(options.startData){
      filter += `&StartDate=${options.startData}`
    }
    if(options.endData){
      filter += `&EndDate=${options.endData}`
    }


    const response: apiReturn = await api.get(`ImportacoesLeads?&Page=${options.pageIndex}&PageSize=${options.pageSize}${filter}`);

    return {
      rows: response.data.items,
      pageCount: response.data.totalCount
    }
}