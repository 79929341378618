import api from 'services/api';
import { apiReturn } from 'types/data-response';
import { filterTable } from 'views/leads/lista/components/SearchTableOverview';



export interface Campanha{
  id: number;
  idPromotorVendas: number;
  idFranquia: number;
  nome: string
  status: string
  dataVigenciaInicio: string
  dataVigenciaFim: string
}

  export async function fetchDataCampanha(options: filterTable) {

    var filter = ''
    if(options.globalFilter){
      filter += `&Filtro=${options.globalFilter}`
    }
    if(options.status){
      filter += `&Status=${options.status}`
    }
    if(options.startData){
      filter += `&DtInicioVigencia=${options.startData}`
    }
    if(options.endData){
      filter += `&DtFimVigencia=${options.endData}`
    }

    const response: apiReturn = await api.get(`CampanhaLeads?&Page=${options.pageIndex}&PageSize=${options.pageSize}${filter}`);

    return {
      rows: response.data.items,
      pageCount: response.data.totalCount
    }
}