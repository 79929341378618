import api from 'services/api';
import { maskCpfCnpj, maskTelefone } from 'util/maskInputs';
import { useToast } from '@chakra-ui/react';
import { apiReturn } from 'types/data-response';
import { filterTable } from '../components/SearchTableOverview';

export interface leads {
  nome: string;
  cpfCnpj?: string;
  email: string;
  statusLead: string;
  telefone?: string;
}

export async function fetchDataLeads(options: filterTable) {
  var filter = '';

  if (options.globalFilter) {
    filter += `&Filtro=${options.globalFilter}`;
  }
  if (options.idImportacao) {
    filter += `&IdImportacao=${options.idImportacao}`;
  }
  if (options.status) {
    filter += `&StatusLead=${options.status}`;
  }
  const response: apiReturn = await api.get(
    `leads?Page=${options.pageIndex}&PageSize=${options.pageSize}${filter}`,
  );

  var tableRow: leads[] = [];

  response.data.items.forEach((elemt) => {
    var row: leads = {
      cpfCnpj: elemt.cpfCnpj ? maskCpfCnpj(elemt.cpfCnpj) : null,
      telefone: elemt.telefone ? maskTelefone(elemt.telefone) : null,
      email: elemt.email,
      nome: elemt.nome,
      statusLead: elemt.statusLead,
    };

    tableRow.push(row);
  });

  return {
    rows: tableRow,
    pageCount: response.data.totalCount,
  };
}
