// Chakra imports
import {
  Flex,
  SimpleGrid,
  Icon,
  useColorModeValue,
  Box,
  Button,
  useToast,
  Badge,
  Text,
  Link,
} from '@chakra-ui/react';

// Custom components
import Statistics from 'components/MiniStatistics/index';
import IconBox from 'components/icons/IconBox';

// Assets
import {
  MdOutlineBarChart,
  MdDownloading,
  MdWarning,
  MdVerified,
} from 'react-icons/md';
import api from 'services/api';
import { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { fetchDataLeads } from './hooks/fetchDataLeads';
import { createColumnHelper } from '@tanstack/react-table';
import SearchTableOverview from './components/SearchTableOverview';

type RowObj = {
  nome: string;
  cpfCnpj?: string;
  email: string;
  statusLead: string;
  telefone?: string;
};

type ResponseTotais = {
  status: string;
  total: number;
};

type Totais = {
  angendado: number;
  recusado: number;
  aprovado: number;
  leads: number;
};

export default function ListaLeads() {
  // Chakra Color Mode
  const iconBg = useColorModeValue('secondaryGray.300', 'navy.700');
  const iconColor = useColorModeValue('brand.500', 'white');
  const toast = useToast();

  const [statusFilter, setStatusFilter] = useState<string>('');

  const [totaisLeads, setTotaisLeads] = useState<Totais>({
    angendado: 0,
    aprovado: 0,
    leads: 0,
    recusado: 0,
  });

  const textColor = useColorModeValue('navy.700', 'white');
  const columnHelper = createColumnHelper<RowObj>();
  const columns = [
    columnHelper.accessor('nome', {
      id: 'nome',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Nome
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('cpfCnpj', {
      id: 'cpfCnpj',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          CPF
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="600">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('email', {
      id: 'email',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          EMAIL
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="600">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('telefone', {
      id: 'telefone',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          TELEFONE
        </Text>
      ),
      cell: (info) => (
        <Text color={'green.500'} fontSize="sm" fontWeight="600">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('statusLead', {
      id: 'statusLead',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          STATUS
        </Text>
      ),
      cell: (info) => (
        <Badge
          colorScheme={
            info.getValue() === 'RECUSADO'
              ? 'red'
              : info.getValue() === 'AGENDADO'
              ? 'orange'
              : 'green'
          }
          color={
            info.getValue() === 'RECUSADO'
              ? 'red.500'
              : info.getValue() === 'AGENDADO'
              ? 'orange.500'
              : 'green.500'
          }
          fontSize="sm"
          fontWeight="600"
        >
          {info.getValue() ? info.getValue().toLowerCase() : ''}
        </Badge>
      ),
    }),
  ];

  const getToatisLeads = async () => {
    try {
      const response: AxiosResponse<ResponseTotais[]> = await api.get(
        `Leads/Totais`,
      );

      const filteredData = response.data.reduce(
        (accumulator: Record<string, number>, x) => {
          accumulator[x.status] = (accumulator[x.status] || 0) + x.total;
          return accumulator;
        },
        {},
      );

      const totais: Totais = {
        angendado: filteredData['AGENDADO'] || 0,
        aprovado: filteredData['APROVADO'] || 0,
        recusado: filteredData['RECUSADO'] || 0,
        leads: filteredData['Total'] || 0,
      };

      setTotaisLeads(totais);
    } catch (error: any) {
      toast({
        title: `Erro:`,
        description: error,
        status: 'error',
        position: 'top-right',
        isClosable: false,
      });
    }
  };

  useEffect(() => {
    getToatisLeads();
  }, []);

  return (
    <Flex pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex direction="column" width="stretch">
        <SimpleGrid
          columns={{ sm: 1, md: 2, lg: 2, xl: 2, '2xl': 4 }}
          gap="20px"
          mb="20px"
        >
          <Flex>
            <Link
              w="100%"
              onClick={() => {
                setStatusFilter('');
              }}
            >
              <Statistics
                focused={statusFilter === ''}
                bgGradient={
                  statusFilter === '' &&
                  'linear(to-b, brand.400, brand.600)'
                }
                title={'Total de Leads'}
                value={totaisLeads.leads.toString()}
                detail={<Flex align="center"></Flex>}
                shadow="base"
                illustration={
                  <IconBox
                    bgGradient={
                      statusFilter === '' &&
                      'linear(to-b, brand.600, brand.400)'
                    }
                    w="80px"
                    h="80px"
                    bg={statusFilter !== '' && iconBg}
                    icon={
                      <Icon
                        color={statusFilter === '' ? 'white' : iconColor}
                        as={MdOutlineBarChart}
                        w="28px"
                        h="28px"
                      />
                    }
                  />
                }
              />
            </Link>
          </Flex>
          <Flex>
            <Link
              w="100%"
              onClick={() => {
                setStatusFilter('Aprovado');
              }}
            >
              <Statistics
                focused={statusFilter === 'Aprovado'}
                bgGradient={
                  statusFilter === 'Aprovado' &&
                  'linear(to-b, brand.400, brand.600)'
                }
                title={'Leads Aprovados'}
                value={totaisLeads.aprovado.toString()}
                shadow="base"
                detail={<Flex align="center"></Flex>}
                illustration={
                  <IconBox
                    w="80px"
                    h="80px"
                    bgGradient={
                      statusFilter === 'Aprovado' &&
                      'linear(to-b, brand.600, brand.400)'
                    }
                    bg={statusFilter !== 'Aprovado' && iconBg}
                    icon={
                      <Icon
                        as={MdVerified}
                        w="38px"
                        h="38px"
                        color={
                          statusFilter === 'Aprovado' ? 'white' : iconColor
                        }
                      />
                    }
                  />
                }
              />
            </Link>
          </Flex>
          <Flex>
            <Link
              w="100%"
              onClick={() => {
                setStatusFilter('Recusado');
              }}
            >
              <Statistics
                title={'Leads Recusados'}
                focused={statusFilter === 'Recusado'}
                bgGradient={
                  statusFilter === 'Recusado' &&
                  'linear(to-b, brand.400, brand.600)'
                }
                value={totaisLeads.recusado.toString()}
                detail={<Flex align="center"></Flex>}
                shadow="base"
                illustration={
                  <IconBox
                    w="80px"
                    h="80px"
                    bgGradient={
                      statusFilter === 'Recusado' &&
                      'linear(to-b, brand.600, brand.400)'
                    }
                    bg={statusFilter !== 'Recusado' && iconBg}
                    icon={
                      <Icon
                        color={
                          statusFilter === 'Recusado' ? 'white' : iconColor
                        }
                        as={MdWarning}
                        w="38px"
                        h="38px"
                      />
                    }
                  />
                }
              />
            </Link>
          </Flex>
          <Flex>
            <Link
              w="100%"
              onClick={() => {
                setStatusFilter('Agendado');
              }}
            >
              <Statistics
                focused={statusFilter === 'Agendado'}
                bgGradient={
                  statusFilter === 'Agendado' &&
                  'linear(to-b, brand.400, brand.600)'
                }
                title={'Leads Agendados'}
                value={totaisLeads.angendado.toString()}
                detail={<Flex align="center"></Flex>}
                shadow="base"
                illustration={
                  <IconBox
                    bgGradient={
                      statusFilter === 'Agendado' &&
                      'linear(to-b, brand.600, brand.400)'
                    }
                    w="80px"
                    h="80px"
                    bg={statusFilter !== 'Agendado' && iconBg}
                    icon={
                      <Icon
                        color={
                          statusFilter === 'Agendado' ? 'white' : iconColor
                        }
                        as={MdDownloading}
                        w="38px"
                        h="38px"
                      />
                    }
                  />
                }
              />
            </Link>
          </Flex>
        </SimpleGrid>

        <Box pt="5px" boxShadow="base">
          <SearchTableOverview fetchData={fetchDataLeads} columns={columns} statusLead={statusFilter}/>
        </Box>
      </Flex>
    </Flex>
  );
}
